import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function AppCard({ app }) {
    const navigate = useNavigate();
    return (
        <div 
            className="card bg-white shadow-xl hover:shadow-2xl transition-all duration-300 cursor-pointer h-full"
            onClick={() => navigate(`/apps/${app.id}/dashboard`)}
        >
            <div className="card-body">
                <div className="flex items-center mb-4">
                    {app.logo_url && (
                        <div className="avatar mr-4 flex-shrink-0">
                            <div className="w-14 h-14 rounded-full">
                                <img 
                                    src={app.logo_url} 
                                    alt={`${app.name} Logo`} 
                                />
                            </div>
                        </div>
                    )}
                    <h2 className="card-title text-lg md:text-xl truncate">{app.name}</h2>
                </div>
                <p className="text-sm md:text-base mb-4 line-clamp-2">{app.user_description || 'No description available'}</p>
                <div className="card-actions justify-end">
                    <p className="text-xs md:text-sm text-base-content/70">
                        {moment(app.created_date).fromNow()} by {app.created_by}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AppCard;