import React, { useCallback, useState, useEffect } from 'react'
import { X, Upload, File as FileIcon, Paperclip } from 'lucide-react'
import { useApp } from "../../../../context/AppContext";

export default function FileUpload({ value, onChange, isMulti }) {
  const { integrationsSDK } = useApp();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (value) {
      const initialFiles = isMulti ? value : [value];
      setFiles(initialFiles.map(fileUrl => ({
        name: fileUrl.split('/').pop(),
        url: fileUrl
      })));
    }
  }, [value, isMulti]);

  const onDrop = useCallback(async (acceptedFiles) => {
    const uploadedFiles = await Promise.all(acceptedFiles.map(async (file) => {
      const { file_url } = await integrationsSDK.upload_file({ file });
      return {
        name: file.name,
        url: file_url,
        preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : undefined
      };
    }));

    setFiles(prevFiles => {
      const newFiles = isMulti ? [...prevFiles, ...uploadedFiles] : uploadedFiles;
      updateOnChange(newFiles);
      return newFiles;
    });
  }, [isMulti, integrationsSDK, onChange]);

  const removeFile = (fileToRemove) => {
    setFiles(files => {
      const newFiles = files.filter(file => file !== fileToRemove);
      updateOnChange(newFiles);
      return newFiles;
    });
    if (fileToRemove.preview) {
      URL.revokeObjectURL(fileToRemove.preview);
    }
  };

  const updateOnChange = (newFiles) => {
    if (isMulti) {
      onChange(newFiles.map(file => file.url));
    } else {
      onChange(newFiles.length > 0 ? newFiles[0].url : null);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    onDrop(droppedFiles);
  };

  return (
    <div className="w-full max-w-md">
      <div
        className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center cursor-pointer transition-colors hover:border-primary"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={() => document.getElementById('fileInput')?.click()}
      >
        <div className="flex items-center justify-center">
          <Upload className="h-6 w-6 text-gray-400 mr-2" />
          <p className="text-sm text-gray-600">Drop files or click to upload</p>
        </div>
        <input
          id="fileInput"
          type="file"
          multiple={isMulti}
          className="hidden"
          onChange={(e) => onDrop(Array.from(e.target.files || []))}
        />
      </div>
      {files.length > 0 && (
        <div className="mt-3 max-h-40 overflow-y-auto">
          {files.map((file, index) => (
            <div key={index} className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-md mb-2">
              <div className="flex items-center space-x-2 truncate">
                {file.preview ? (
                  <img src={file.preview} alt={file.name} className="w-8 h-8 object-cover rounded" />
                ) : (
                  <FileIcon className="w-8 h-8 text-gray-400" />
                )}
                <div className="truncate">
                  <a href={file.url} target="_blank" rel="noopener noreferrer" className="text-xs font-medium text-gray-900 truncate flex items-center">
                    <Paperclip className="w-3 h-3 mr-1" />
                    {file.name}
                  </a>
                </div>
              </div>
              <button
                onClick={() => removeFile(file)}
                className="text-red-500 hover:text-red-700 transition-colors ml-2 flex-shrink-0"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}