import { useApp } from '../../../context/AppContext';
import { User } from 'lucide-react';
import { useMediaQuery } from 'react-responsive';
import { useAuth } from '../../../context/AuthContext';

export default function AppDemoHeader({page}){
    const {app, userInfo} = useApp();
    const {logout} = useAuth();
    const isMobile = useMediaQuery({ maxWidth: 767 });

    return <div className="navbar bg-base-100">
        <div className="flex-1">
            {app.logo_url && (
                <img src={app.logo_url} alt={`${app.name} logo`} className="w-10 h-10 mr-2 rounded-full object-cover" />
            )}
            {!isMobile && <a className='text-xl px-4'>{page.title}</a>}
        </div>
        <div className="flex-none">
        {isMobile ? (
            <ul className="menu menu-horizontal px-1">
                <li>
                    <details>
                        <summary>{page.title}</summary>
                        <ul className="bg-base-100 rounded-t-none p-2">
                            {app.pages.filter(p => p.id !== page.id).map(otherPage => (
                                <li key={otherPage.id}>
                                    <a href={`/apps/${app.id}/show/${otherPage.name}`}>{otherPage.title}</a>
                                </li>
                            ))}
                            {
                                userInfo._app_role === "admin" && (
                                    <li>
                                        <a href={`/apps/${app.id}/dashboard`}>App Dashboard</a>
                                    </li>
                                )
                            }
                        </ul>
                    </details>
                </li>
            </ul>
        ) : (
            <ul className="menu menu-horizontal px-1">
                {app.pages.filter(p => p.id !== page.id && !(p.url_parameters?.length)).map(otherPage => (
                    <li key={otherPage.id}>
                        <a href={`/apps/${app.id}/show/${otherPage.name}`}>{otherPage.title}</a>
                    </li>
                ))}
                {
                    userInfo._app_role === "admin" && (
                        <li>
                            <a className='btn btn-sm btn-primary' href={`/apps/${app.id}/dashboard`}>App Dashboard</a>
                        </li>
                    )
                }
            </ul>
        )}
        <div className="dropdown dropdown-end">
            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar flex items-center justify-center">
                <User className="w-6 h-6" />
            </div>
            <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow-xl border border-base-300">
                    <li className="font-bold">
                        <span className="block truncate">{userInfo.full_name}</span>
                        <span className="block truncate max-w-xs text-xs">
                            <span className="inline-block max-w-full overflow-hidden text-ellipsis">{userInfo.email}</span>
                        </span>
                    </li>
                <li><a onClick={logout}>Logout</a></li>
            </ul>
        </div>
        </div>
    </div>
}