import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataImporterAPI from "../../api/DataImporterAPI";
import Chat from '../../components/chat/Chat'

const DataImporterState = ({ dataImporter }) => {
    if (!dataImporter.current_script) {
        return null;
    }

    return (
        <div className="w-1/2 pl-4">
            <div className="bg-gray-100 p-4 rounded-lg mb-4">
                <h2 className="text-xl font-bold mb-2">Current Script</h2>
                <pre className="bg-white p-2 rounded overflow-x-auto">
                    <code>{dataImporter.current_script}</code>
                </pre>
            </div>
            {dataImporter.output_file_uri && (
                <div className="bg-gray-100 p-4 rounded-lg">
                    <h2 className="text-xl font-bold mb-2">Output File</h2>
                    <a 
                        href={dataImporter.output_file_url} 
                        download 
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block"
                    >
                        Download Output File
                    </a>
                </div>
            )}
        </div>
    );
};

export default function DataImporterPage() {
    const { dataImporterId } = useParams();
    const [dataImporter, setDataImporter] = useState();

    useEffect(() => {
        const init = async () => {
            await DataImporterAPI.subscribe(dataImporterId, (fetchedDataImporter) => {
                setDataImporter(fetchedDataImporter);
            });
        }
        init();
    }, [dataImporterId]);

    if (!dataImporter) {
        return <div className="flex justify-center items-center h-screen">
            <span className="loading loading-spinner loading-lg"></span>
        </div>
    }

    const submitUserInputTool = (toolCallId, values) => {
        return DataImporterAPI.submitUserInputTool(dataImporterId, toolCallId, values).then(setDataImporter);
    }

    const addMessage = (msg) => {
        return DataImporterAPI.addMessage(dataImporterId, msg).then(setDataImporter);
    }

    const editMessage = (msg) => {
        return DataImporterAPI.editMessage(dataImporterId, msg).then(setDataImporter);
    }

    const ChatElement = <Chat chat={dataImporter.conversation} addMessage={addMessage} editMessage={editMessage} submitUserInputTool={submitUserInputTool} status={dataImporter.status} />

    const renderContent = () => {
        if (true || !dataImporter.current_script) {
            return (
                <div className="max-w-4xl mx-auto">
                    {ChatElement}
                </div>
            );
        } else {
            return (
                <div className="flex">
                    <div className="w-1/2 pr-4">
                        {ChatElement}
                    </div>
                    <DataImporterState dataImporter={dataImporter} />
                </div>
            );
        }
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-6">{dataImporter.name}</h1>
            {dataImporter.description && (
                <p className="text-gray-600 mb-4">{dataImporter.description}</p>
            )}
            {renderContent()}
        </div>
    );
}