import React, { useState } from 'react';
import Modal from './Modal';
import MetaJsonSchemaForm from './MetaJsonSchemaForm';
import { Calendar, Mail, Link, List, Hash, ToggleLeft, FileText, Box, ExternalLink } from 'lucide-react';

function getTypeIcon(type, prop) {
    if (prop.$ref) {
        return <ExternalLink size={16} />;
    }

    switch (type) {
        case 'array':
            return <List size={16} />;
        case 'date-time':
        case 'date':
            return <Calendar size={16} />;
        case 'email':
            return <Mail size={16} />;
        case 'uri':
        case 'url':
            return <Link size={16} />;
        case 'number':
            return <Hash size={16} />;
        case 'boolean':
            return <ToggleLeft size={16} />;
        case 'object':
            return <Box size={16} />;
        default:
            return <FileText size={16} />;
    }
}

function getType(prop) {
    if (prop.type === 'array') {
        return 'array';
    }
    if (prop.type === 'string'){
        if (prop.format === 'date-time'){
            return 'date-time';
        }
        if (prop.format === 'date'){
            return 'date';
        }
        if (prop.format === 'email'){
            return 'email';
        }
        if (prop.format === 'uri'){
            return 'uri';
        }
        if (prop.format === 'url'){
            return 'url';
        }
        if (prop.$ref){
            const parts = prop.$ref.split('/');
            return parts[parts.length - 2];
        }
        return 'text';
    }
    return prop.type;
}

function PropertyDisplay({ name, prop, schema, onEdit }) {
    const additionalProps = Object.entries(prop).filter(([key]) => !['type', 
        'description', 'default', 'title', 'format', '$ref', 'enum', 'items', 'properties', 'required'].includes(key));
    const type = getType(prop);
    return (
        <li key={name} className="py-4">
            <div className="flex flex-col space-y-2">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <h5 className="font-semibold text-gray-900 me-2">{prop.title || name}</h5>
                        {"  ("} {getTypeIcon(type, prop)}
                        <span className="ml-1">{type}</span>
                        {schema.required && schema.required.includes(name) && (
                            <span className="ml-2">, required</span>
                        )}
                        {")"}
                    </div>
                    {onEdit && prop.type !== "object" && (
                        <button
                            onClick={() => onEdit(name, prop)}
                        className="btn btn-sm btn-outline"
                    >
                        Edit
                    </button>
                    )}
                </div>
                {/* <div className="text-sm text-gray-600 flex items-center">
                    <span className="font-medium mr-1">Type:</span>
                    {getTypeIcon(type, prop)}
                    <span className="ml-1">{type}</span>
                </div> */}
                {prop.description && (
                    <p className="text-sm text-gray-700">{prop.description}</p>
                )}
                {prop.default !== undefined && (
                    <div className="text-sm">
                        <span className="font-medium">Default:</span> {JSON.stringify(prop.default)}
                    </div>
                )}
                {
                    prop.enum && (
                        <div className="text-sm">
                            <span className="font-medium">Options:</span> {prop.enum.join(', ')}
                        </div>
                    )
                }
                {
                    prop.items && (
                        <div className="ps-6 border-s-4 border-gray-300">
                            <ReadableJSONSchema schema={prop.items} />
                        </div>
                    )
                }
                {
                    prop.properties && (
                        <div className="ps-6 border-s-4 border-gray-300">
                            <ReadableJSONSchema schema={prop} />
                        </div>
                    )
                }
                {additionalProps.length > 0 && (
                    <div className="mt-2">
                        <h4 className="text-sm font-medium text-gray-900">Additional Properties:</h4>
                        <ul className="mt-1 space-y-1">
                            {additionalProps.map(([key, value]) => (
                                <li key={key} className="text-sm text-gray-600">
                                    <span className="font-medium">{key}:</span> {JSON.stringify(value)}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </li>
    );
}

export default function ReadableJSONSchema({ schema, onEdit }) {
    const [editingField, setEditingField] = useState(null);

    const handleEditField = (name, prop) => {
        setEditingField({ name, prop });
    };

    const handleCloseModal = () => {
        setEditingField(null);
    };

    const handleSaveField = (updatedProp) => {
        if (onEdit) {
            onEdit(editingField.name, updatedProp);
        }
        setEditingField(null);
    };

    return (
        <>
            <ul className="divide-y divide-gray-200">
                {Object.entries(schema.properties || {}).map(([name, prop]) => (
                    <PropertyDisplay
                        key={name}
                        name={name}
                        prop={prop}
                        schema={schema}
                        onEdit={onEdit ? handleEditField : null}
                    />
                ))}
            </ul>
            {editingField && (
                <Modal
                    isOpen={!!editingField}
                    onClose={handleCloseModal}
                    title={`Edit ${editingField.name}`}
                >
                    <MetaJsonSchemaForm
                        value={editingField.prop}
                        onSubmit={handleSaveField}
                    />
                </Modal>
            )}
        </>
    );
}