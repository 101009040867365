import { useApp } from "../../context/AppContext";
import Chat from "../../components/chat/Chat";

export default function AppChat() {
    const { app, addChatMessage, editChatMessage } = useApp();
    return (
        <div className="h-full absolute w-full">
            <Chat chat={app.conversation} addMessage={addChatMessage} status={app.status} editMessage={editChatMessage} />
        </div>
    )
}