import { BaseAPIModelProvider } from './base.ts'

class UserAppAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/apps'
    }

    createFromMessage(msg) {
        return this.axios.post('/chat/create-from-message', msg)
    }

    addMessage(appId, message) {
        return this.axios.post(`/${appId}/chat/message`, message)
    }

    editMessage(appId, messageId, message) {
        return this.axios.put(`/${appId}/chat/message/${messageId}`, message)
    }

    codePage(appId, pageId) {
        return this.axios.post(`/${appId}/pages/${pageId}/generate-code`)
    }

    editPageCode(appId, pageId, changes, image) {
        return this.axios.post(`/${appId}/pages/${pageId}/edit-code`, { changes, image_as_url: image })
    }

    compilePageCode(appId, pageId) {
        return this.axios.get(`/${appId}/pages/${pageId}/compile-code`)
    }

    manualEditPageCode(appId, pageId, code) {
        return this.axios.post(`/${appId}/pages/${pageId}/manual-edit-code`, { code })
    }

    loadCheckpoint(appId, checkpointId) {
        return this.axios.post(`/${appId}/history/${checkpointId}/load-checkpoint`)
    }

    runScript(appId, scriptId, inputs) {
        return this.axios.post(`/${appId}/scheduled-actions/${scriptId}/run`, inputs)
    }

    listScriptRuns(appId, scriptId) {
        return this.axios.get(`/${appId}/scheduled-actions/${scriptId}/runs`)
    }

    regenerateScript(appId, scriptId) {
        return this.axios.post(`/${appId}/scheduled-actions/${scriptId}/generate-code`)
    }

    aiEditScriptCode(appId, scriptId, changes) {
        return this.axios.post(`/${appId}/scheduled-actions/${scriptId}/edit-code`, { changes })
    }

    manualEditScriptCode(appId, scriptId, code) {
        return this.axios.post(`/${appId}/scheduled-actions/${scriptId}/manual-edit-code`, { code })
    }

    registerUser(appId) {
        return this.axios.post(`/${appId}/users/register`)
    }


    inviteUser(appId, userEmail, role) {
        return this.axios.post(`/${appId}/users/invite-user`, { user_email: userEmail, role })
    }

    updateUserRole(appId, userEmail, role) {
        return this.axios.post(`/${appId}/users/update-user-role`, { user_email: userEmail, role })
    }

    cloneApp(appId) {
        return this.axios.post(`/${appId}/clone`)
    }

    getMyUserInfo(appId) {
        return this.axios.get(`/${appId}/entities/User/me`)
    }

    updateUserData(appId, userId, userData) {
        return this.axios.put(`/${appId}/entities/User/${userId}`, userData)
    }

    generateLogo(appId) {
        return this.axios.post(`/${appId}/generate-logo`)
    }

    publishApp(appId) {
        return this.axios.post(`/${appId}/publish`)
    }

    unpublishApp(appId) {
        return this.axios.post(`/${appId}/unpublish`)
    }
}


export default new UserAppAPI();