import { BaseAPIModelProvider } from './base.ts'

class AuthAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/users'
    }

    login(username, password) {
        return this.axios.post('/token', {username, password})
    }

    getCurrentUser() {
        return this.axios.get('/me')
    }

    regenerateApiKey(userId){
        return this.axios.post(`/${userId}/regenerate_api_key`)
    }
}

export default new AuthAPI();