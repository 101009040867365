import Home from "./HomePage";
import UserSettings from "./UserSettings";
import UserAppDashboard from "./UserAppDashboard";
import UserAppDemo from "./UserAppDemo";
import UserApps from "./UserApps";
import AppUserFacingChat from "./AppUserFacingChat";
import { useParams } from "react-router-dom";
import { Home as HomeIcon, Edit, Settings, Code, MessageSquare, Layers } from 'lucide-react';
import DataImporterPage from "./data-importer/DataImporterPage";
import DataImporterDashboard from "./data-importer/DataImporterDashboard";
import { useNavigate } from "react-router-dom";

function DashbaordWrapper() {
    const { appId } = useParams();
    return <UserAppDashboard appId={appId} />;
}

function ProxyPageComponent({urlChangeHandler}) {
    const currentUrl = window.location.pathname;
    const newUrl = urlChangeHandler(currentUrl);
    console.log("ProxyPageComponent", currentUrl, newUrl);
    if (newUrl !== currentUrl) {
        window.location.href = newUrl;
    }
    return <div>Loading...</div>;
    
}

const pages = [
    { 
        name: 'Home', 
        url: '/', 
        component: <Home />, 
        icon: <HomeIcon /> 
    },
    {
        name: 'User Apps', 
        url: '/user-apps/:appId/show/:pageName?', 
        component: <ProxyPageComponent urlChangeHandler={(url) => url.replace('/user-apps/', '/apps/')} />, 
        icon: <Layers />,
        showInMenu: false
    },
    { 
        name: 'User Apps', url: '/apps/', component: <UserApps />, icon: <Layers />,
        children: [
            {
                name: 'User App Dashboard', 
                url: '/apps/:appId/dashboard', 
                component: <DashbaordWrapper />, 
                icon: <Layers />,
            },
            { 
                name: 'Show User App', 
                url: '/apps/:appId/show/:pageName?', 
                component: <UserAppDemo isEditMode={false} />, 
                icon: <Code />,
                usesLayout: false
            },
            { 
                name: 'Edit User App', url: '/apps/:appId/edit/:pageName', 
                component: <UserAppDemo isEditMode={true} />, 
                icon: <Edit />,
                usesLayout: false
            },
            {
                name: 'App User Facing Chat',
                url: '/apps/:appId/chat',
                component: <AppUserFacingChat />,
                icon: <MessageSquare />,
                usesLayout: false
            }
        ]
    },
    {
        name: 'Data Importer',
        url: '/data-importer',
        component: <DataImporterDashboard />,
        icon: <MessageSquare />,
        children: [
            {
                name: 'Data Importer',
                url: '/data-importer/:dataImporterId',
                component: <DataImporterPage />,
                icon: <MessageSquare />,
            }
        ]
    },
    { 
        name: 'User Settings', 
        url: '/user-settings',
        hide: true,
        component: <UserSettings />,
        icon: <Settings /> 
    }
]

  
export {
    pages
};