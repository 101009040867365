import moment from 'moment';

export default function DateInput({ value, onChange, format = "date" }) {
    const _handleOnChange = (e) => {
      onChange(e.target.value)
    }
    let parsedValue = value;
    if (format === "date") {
      parsedValue = moment(value).format('YYYY-MM-DD');
    } else if (format === "date-time") {
      parsedValue = moment(value).format('YYYY-MM-DDTHH:mm:ss');
    } else if (format === "time") {
      parsedValue = value; 
    }
    
    return <input type={format} className="input input-bordered w-full" value={parsedValue} onChange={_handleOnChange} />;
  }
  