import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Send, Loader } from 'lucide-react';
import UserAppAPI from "../api/UserAppAPI";
import AppCard from "../components/user-app/AppCard";
import logoImage from '../logo-v1.png';


const STARTERS = [
    {
        "shortDescription": "TODO list",
        "description": "Create a simple todo list app to manage my tasks. make sure i can separate work and personal tasks." +
            "\nAlso, I want a daily reminder via email to check off tasks that are due that day."
    },
    {
        "shortDescription": "Perplexity clone - LLM Search engine",
        "description": "Create a search engine powered by an LLM, similar to Perplexity. The app should be able to answer questions by searching the internet and providing detailed, contextual responses. Include features like source citations, follow-up questions, and the ability to dive deeper into topics."
    },
    {
        "shortDescription": "Water reminder",
        "description": "Create a water reminder app that sends me a notification to drink water every 3 hours during the day."
    },
    {
        "shortDescription": "CRM for a Tattoo shop",
        "description": "Create a CRM system for a tattoo shop. Include features for managing client information, appointment scheduling, tracking tattoo designs and artists' portfolios. Also, implement a feature for sending appointment reminders and follow-up care instructions via email or SMS."
    },
    {
        "shortDescription": "GPU marketplace",
        "description": "Create a marketplace for buying and selling used GPUs. Include features for filtering GPUs by brand, model, price, and other specifications. Also, implement a feature for sending notifications when new GPUs matching my search criteria are available."
    },
    {
        "shortDescription": "Split expenses app",
        "description": "Create a split expenses app for a group of friends. Include features for managing expenses, tracking who owes whom, and splitting bills evenly or by percentage. Also, implement a feature for sending reminders and requesting payments from friends."
    }
]

const AppConversationStarter = ({ onStart, loading }) => {
    const [appDescription, setAppDescription] = useState('');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleStart();
        }
    };

    const handleStart = () => {
        const trimmedDescription = appDescription.trim();
        if (trimmedDescription) {
            onStart(trimmedDescription);
            setAppDescription('');
        }
    };

    const handleBubbleClick = (description) => {
        setAppDescription(description);
    };

    return (
        <div>
            <div className="relative">
                <textarea
                    rows={4}
                    value={appDescription}
                    onChange={(e) => setAppDescription(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Describe the app you want to create..."
                    className="textarea textarea-bordered w-full pr-12 text-base"
                />
                <button
                    onClick={handleStart}
                    className="btn btn-circle btn-ghost absolute right-2 bottom-2"
                    disabled={loading || !appDescription.trim()}
                >
                    {loading ? <Loader className="animate-spin" /> : <Send />}
                </button>
            </div>
            <div className="flex flex-wrap gap-2 mt-4">
                {STARTERS.map((starter, index) => (
                    <button
                        key={index}
                        className="btn btn-sm btn-outline"
                        onClick={() => handleBubbleClick(starter.description)}
                    >
                        {starter.shortDescription}
                    </button>
                ))}
            </div>
        </div>
    );
};

function Home() {
    const [apps, setApps] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchApps();
    }, []);

    const fetchApps = async () => {
        setIsLoading(true);
        try {
            const appList = await UserAppAPI.list('updated_date', 20);
            setApps(appList);
        } catch (error) {
            console.error('Failed to fetch apps:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateApp = async (appDescription) => {
        if (!appDescription.trim()) {
            alert('Please enter an app description');
            return;
        }
        setIsCreating(true);
        try {
            const newApp = await UserAppAPI.create({
                status: {state: "processing", details: "Starting conversation..."}
            });
            UserAppAPI.addMessage(newApp.id, {content: appDescription});
            navigate(`/apps/${newApp.id}/dashboard`);
        } catch (error) {
            console.error('Failed to create app:', error);
            alert('Failed to create app. Please try again.');
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 max-w-4xl">
            <div className="text-center mb-12">
                <h1 className="text-4xl font-bold mb-4">Welcome to Base44</h1>
                <p className="text-xl text-gray-600">
                    Create beautiful, useful apps with ease.
                </p>
            </div>            

            <div className="card bg-white shadow-xl mb-12">
                <div className="card-body">
                    <h2 className="card-title text-2xl flex items-center mb-4">
                        <img src={logoImage} alt="Base44 Logo" className="w-8 h-8 mr-2" />
                        Create New App
                    </h2>
                    <AppConversationStarter onStart={handleCreateApp} loading={isCreating} />
                </div>
            </div>

            <h2 className="text-2xl font-bold mb-6">Your Recent Apps</h2>
            
            {isLoading ? (
                <div className="flex justify-center">
                    <span className="loading loading-spinner loading-lg"></span>
                </div>
            ) : apps.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {apps.map((app, index) => (
                        <AppCard key={index} app={app} />
                    ))}
                </div>
            ) : (
                <div className="text-center py-12">
                    <h3 className="text-xl mb-4">You haven't created any apps yet.</h3>
                    <p className="text-gray-600">Start by creating your first app above!</p>
                </div>
            )}
        </div>
    );
}

export default Home;
