import React, { useState, useEffect } from 'react';
import { Save } from 'lucide-react';
import UserAppAPI from '../../../api/UserAppAPI';
import DynamicForm from '../../../lib/components/json-schema-form';
import moment from 'moment';
import CodeEditor from '../../CodeEditor';
import Tabs from '../../Tabs';

const ScriptCodeEditor = ({ appId, script }) => {
    const [code, setCode] = useState(script.code || '');
    const [isEditing, setIsEditing] = useState(false);
    const [aiEditModalVisible, setAiEditModalVisible] = useState(false);
    const [aiEditPrompt, setAiEditPrompt] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const handleCodeChange = (newCode) => {
        setCode(newCode);
        setIsEditing(true);
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await UserAppAPI.manualEditScriptCode(appId, script.id, code);
            alert('Script code saved successfully');
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving script code:', error);
            alert('Failed to save script code');
        } finally {
            setIsSaving(false);
        }
    };

    const handleAiEdit = async () => {
        setIsSaving(true);
        try {
            const updatedApp = await UserAppAPI.aiEditScriptCode(appId, script.id, aiEditPrompt);
            alert('Script code updated successfully');
            const updatedScript = updatedApp.scheduled_actions.find(s => s.id === script.id);
            setCode(updatedScript.code);
            setAiEditModalVisible(false);
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating script code:', error);
            alert('Failed to update script code');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div className="relative min-h-[600px]">
            <div className="absolute top-2 right-2 z-10">
                {isEditing && (
                    <button
                        className="btn btn-primary mr-2"
                        onClick={handleSave}
                        disabled={isSaving}
                    >
                        <Save className="w-4 h-4 mr-2" />
                        {isSaving ? 'Saving...' : 'Save'}
                    </button>
                )}
                <button
                    className="btn btn-secondary"
                    onClick={() => setAiEditModalVisible(true)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M12 2a10 10 0 1 0 10 10H12V2z"/>
                        <path d="M12 12L2 12"/>
                        <path d="M12 12L17 7"/>
                        <path d="M12 12L17 17"/>
                    </svg>
                    AI Edit
                </button>
            </div>
            <CodeEditor
                value={code}
                onChange={handleCodeChange}
                language="python"
                readOnly={false}
                className="min-h-[600px]"
            />
            {aiEditModalVisible && (
                <div className="modal modal-open">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">AI Edit Script</h3>
                        <textarea
                            className="textarea textarea-bordered w-full mt-2"
                            value={aiEditPrompt}
                            onChange={(e) => setAiEditPrompt(e.target.value)}
                            placeholder="Describe how you want to change the code..."
                            rows={4}
                        />
                        <div className="modal-action">
                            <button className="btn" onClick={() => setAiEditModalVisible(false)}>Cancel</button>
                            <button className="btn btn-primary" onClick={handleAiEdit} disabled={isSaving}>
                                {isSaving ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const HistoricalRuns = ({ appId, scriptId, refreshTrigger }) => {
    const [runs, setRuns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [selectedRun, setSelectedRun] = useState(null);

    useEffect(() => {
        const fetchRuns = async () => {
            setLoading(true);
            try {
                const response = await UserAppAPI.listScriptRuns(appId, scriptId);
                setRuns(response);
            } catch (error) {
                console.error('Error fetching historical runs:', error);
                alert('Failed to fetch historical runs');
            } finally {
                setLoading(false);
            }
        };

        fetchRuns();
    }, [appId, scriptId, refreshTrigger]);

    if (loading) return <div className="loading loading-spinner loading-lg"></div>;

    const showDetails = (run) => {
        setSelectedRun(run);
        setDetailsModalVisible(true);
    };

    const renderOutput = (output) => {
        if (output === null || output === undefined || (typeof output === 'object' && Object.keys(output).length === 0)) {
            return <span className="badge badge-ghost">Empty</span>;
        }
        if (typeof output === 'string') {
            return output;
        } else if (typeof output === 'object') {
            return JSON.stringify(output, null, 2);
        }
        return '';
    };

    const isInputEmpty = (input) => {
        return input === null || input === undefined || (typeof input === 'object' && Object.keys(input).length === 0);
    };

    return (
        <>
            <ul className="menu bg-base-200 w-full rounded-box">
                {runs.map((run, index) => (
                    <li key={index} className="hover-bordered">
                        <div className="flex justify-between items-center w-full">
                            <div>
                                <span className="font-bold">{moment(run.created_date).fromNow()}</span>
                                <span className={`badge ml-2 ${run.results.errors ? 'badge-error' : 'badge-success'}`}>
                                    {run.results.errors ? 'Error' : 'Success'}
                                </span>
                                {!isInputEmpty(run.input_values) && (
                                    <p className="text-sm">Input: {JSON.stringify(run.input_values)}</p>
                                )}
                                <p className="text-sm truncate">
                                    Output: {renderOutput(run.results.output)}
                                </p>
                            </div>
                            <button className="btn btn-sm" onClick={() => showDetails(run)}>Details</button>
                        </div>
                    </li>
                ))}
            </ul>
            {detailsModalVisible && (
                <div className="modal modal-open">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Run Details</h3>
                        {selectedRun && (
                            <>
                                <p><strong>Time:</strong> {moment(selectedRun.created_date).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                <p><strong>Status:</strong> {selectedRun.results.errors ? 'Error' : 'Success'}</p>
                                {!isInputEmpty(selectedRun.input_values) && (
                                    <div>
                                        <strong>Input:</strong>
                                        <pre className="bg-base-300 p-2 rounded">{JSON.stringify(selectedRun.input_values, null, 2)}</pre>
                                    </div>
                                )}
                                <div>
                                    <strong>Output:</strong>
                                    {renderOutput(selectedRun.results.output) || <p className="text-center">No output</p>}
                                </div>
                                {selectedRun.results.errors && (
                                    <div>
                                        <strong>Errors:</strong>
                                        <pre className="bg-base-300 p-2 rounded">{selectedRun.results.errors}</pre>
                                    </div>
                                )}
                                {selectedRun.results.stdout && (
                                    <div>
                                        <strong>Standard Output:</strong>
                                        <pre className="bg-base-300 p-2 rounded">{selectedRun.results.stdout}</pre>
                                    </div>
                                )}
                            </>
                        )}
                        <div className="modal-action">
                            <button className="btn" onClick={() => setDetailsModalVisible(false)}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default function AppScript({ appId, script }) {
    const [runLoading, setRunLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [formModalVisible, setFormModalVisible] = useState(false);
    const [lastInputs, setLastInputs] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            // Dummy resize handler to prevent undelivered notifications
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOpenFormModal = () => {
        setFormModalVisible(true);
    };

    const handleRunScript = async (inputs) => {
        setRunLoading(true);
        setFormModalVisible(false);
        setModalVisible(true);
        setLastInputs(inputs);
        try {
            const response = await UserAppAPI.runScript(appId, script.id, inputs);
            setResult(response.results);
            setRefreshTrigger(prev => prev + 1);
        } catch (error) {
            console.error('Error running script:', error);
            setResult({ error: 'An error occurred while running the script.' });
        } finally {
            setRunLoading(false);
        }
    };

    const handleFixScript = async () => {
        if (!result || !result.errors || !lastInputs) {
            alert('Unable to fix script: missing error information or inputs');
            return;
        }

        setRunLoading(true);
        try {
            const fixMessage = `I've ran the script with the following inputs: ${JSON.stringify(lastInputs)} and got the following errors: ${result.errors}, please help me fix it`;
            await UserAppAPI.aiEditScriptCode(appId, script.id, fixMessage);
            alert('Script has been updated. Please try running it again.');
            setModalVisible(false);
        } catch (error) {
            console.error('Error fixing script:', error);
            alert('An error occurred while trying to fix the script.');
        } finally {
            setRunLoading(false);
        }
    };

    const generateSchema = () => {
        const schema = {
            type: 'object',
            properties: {}
        };

        if (script.script_inputs) {
            script.script_inputs.forEach(input => {
                schema.properties[input.name] = {
                    type: 'string',
                    title: input.name,
                    description: input.description,
                    default: input.example
                };
            });
        }

        return schema;
    };

    const renderResult = () => {
        if (!result) return null;

        if (typeof result === 'object') {
            return (
                <div className="max-h-96 overflow-y-auto">
                    {Object.entries(result).map(([key, value]) => (
                        <div key={key} className="mb-4">
                            <strong>{key}:</strong>
                            <pre className="whitespace-pre-wrap break-words bg-base-200 p-2 rounded mt-1">
                                {typeof value === 'string' ? value : JSON.stringify(value, null, 2)}
                            </pre>
                        </div>
                    ))}
                    {result.errors && (
                        <button onClick={handleFixScript} className="btn btn-error mt-4">
                            Fix Script
                        </button>
                    )}
                </div>
            );
        } else {
            return (
                <p className="whitespace-pre-wrap break-words">
                    {result}
                </p>
            );
        }
    };

    const tabItems = [
        {
            key: 'historical-runs',
            label: 'Historical Runs',
            component: <HistoricalRuns appId={appId} scriptId={script.id} refreshTrigger={refreshTrigger} />
        },
        ...(script.script_inputs && script.script_inputs.length > 0 ? [{
            key: 'script-inputs',
            label: 'Script Inputs',
            component: (
                <ul className="menu bg-base-200 w-full rounded-box">
                    {script.script_inputs.map((item, index) => (
                        <li key={index} className="hover-bordered">
                            <div>
                                <strong>{item.name}</strong>
                                <p>{item.description}</p>
                                <p className="text-sm opacity-70">Example: {item.example}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            )
        }] : []),
        {
            key: 'view-code',
            label: 'View Code',
            component: <ScriptCodeEditor appId={appId} script={script} />
        }
    ];

    return (
        <div className="card bg-base-100 shadow-xl mb-4">
            <div className="card-body">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="card-title">{script.title}</h2>
                    <button 
                        onClick={handleOpenFormModal} 
                        className="btn btn-primary"
                        disabled={!script.code || runLoading}
                    >
                        {runLoading ? <span className="loading loading-spinner"></span> : 'Run Script'}
                    </button>
                </div>
                <p>{script.description}</p>
                {script.schedule && (
                    <p><strong>Schedule:</strong> {script.schedule}</p>
                )}
                <div>
                    <strong>Libraries:</strong>{' '}
                    {script.libraries && script.libraries.map((lib, index) => (
                        <span key={index} className="badge badge-primary mr-1">{lib}</span>
                    ))}
                </div>
                <Tabs items={tabItems} />
            </div>
            {formModalVisible && (
                <div className="modal modal-open">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Run Script</h3>
                        <DynamicForm
                            schema={generateSchema()}
                            onSubmit={handleRunScript}
                            initialValues={script.script_inputs ? script.script_inputs.reduce((acc, input) => {
                                acc[input.name] = input.example;
                                return acc;
                            }, {}) : {}}
                        />
                        <div className="modal-action">
                            <button className="btn" onClick={() => setFormModalVisible(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
            {modalVisible && (
                <div className="modal modal-open">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Script Result</h3>
                        {runLoading ? (
                            <div className="flex justify-center items-center h-32">
                                <span className="loading loading-spinner loading-lg"></span>
                            </div>
                        ) : (
                            renderResult()
                        )}
                        <div className="modal-action">
                            <button className="btn" onClick={() => setModalVisible(false)}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}