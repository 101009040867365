import React, { createContext, useState, useContext, useEffect } from 'react';
import UserAppAPI from '../api/UserAppAPI';
import { useParams } from 'react-router-dom';
import { entitiesFromApp } from '../api/Entities';
import createIntegrationsClient from '../api/Integrations';
import RegisterToApp from '../components/user-app/RegisterToApp';
import { toast } from 'react-hot-toast';
import _ from 'lodash';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const {appId, pageName} = useParams();
    const [app, setApp] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [integrationsSDK, setIntegrationsSDK] = useState(null);
    const [entitiesSDK, setEntitiesSDK] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);
    const [appErrors, setAppErrors] = useState([]);
    const [showRegister, setShowRegister] = useState(false);
    const [registerInfo, setRegisterInfo] = useState(null);
    const onAppError = (error) => {
      console.log("OnAppError", error);
        setAppErrors(prevErrors => {
            if (!prevErrors.some(e => e.message === error.message)) {
                return [...prevErrors, error];
            }
            return prevErrors;
        });
    }

    

    const init = async () => {
        try {
          setIsLoading(true);
          await UserAppAPI.subscribe(appId, (fetchedApp) => {
              setApp(fetchedApp);
              const appEntitiesSDK = entitiesFromApp(fetchedApp, onAppError);
              setEntitiesSDK(appEntitiesSDK);
              setCurrentPage(fetchedApp.pages.find(page => page.name.toLowerCase() === (pageName || fetchedApp.pages[0].name).toLowerCase()));
          });
          // TODO: subscribe to user info.
          const fetchedUserInfo = await UserAppAPI.getMyUserInfo(appId);
          setUserInfo(fetchedUserInfo);
  
          const integClient = await createIntegrationsClient('/integrations', onAppError);
          setIntegrationsSDK(integClient);
  
          
      } catch (err) {
          if (err.response && err.response.status === 403) {
              setShowRegister(true);
              setRegisterInfo(err.response.data.detail);
          } else {
              setError('Failed to load app: ' + err.message);
          }
      } finally {
          setIsLoading(false);
        }
      };

    
    useEffect(() => {
        init();
    }, [appId]);

  const updateUserInfo = async (userId, newInfo) => {
    try {
      const updatedUserInfo = await UserAppAPI.updateUserData(appId, userId, newInfo);
      setUserInfo(updatedUserInfo);
    } catch (err) {
      console.log(err);
      toast.error("Error updating user info: " + err.message);
    }
  };

  const addChatMessage = async (message) => {
    try {
      const params = currentPage ? {current_page: currentPage.name} : {};
        app.conversation.messages.push({...message, role: 'user'});
        const updatedApp = await UserAppAPI.addMessage(appId, {...message, additional_message_params: params});
        setApp(updatedApp);
    } catch (err) {
        console.error(err);
        setError('Failed to add message: ' + err.message);
    }
  };

  const editChatMessage = async (messageId, message) => {
    try {
      const params = currentPage ? {current_page: currentPage.name} : {};
      let origMsg = app.conversation.messages.find(msg => msg.id === messageId);
      _.assign(origMsg, message);
      const updatedApp = await UserAppAPI.editMessage(appId, messageId, {...message, additional_message_params: params});
      setApp(updatedApp);
    } catch (err) {
      console.error(err);
      setError('Failed to edit message: ' + err.message);
    }
  };

  const updateApp = async (props) => {
    const updatedApp = await UserAppAPI.update(appId, props);
    setApp(updatedApp);
  }

  const makePageChanges = async (pageId, changes, image) => {
    console.log("making page changes", app.id, pageId, changes, image);
    const updatedApp = await UserAppAPI.editPageCode(app.id, pageId, changes, image);
    setApp(updatedApp);
    setAppErrors([]);
}

  let element = children

  if (showRegister) {
    element = <RegisterToApp 
        appId={appId}
        appName={registerInfo.app_name}
        appDescription={registerInfo.app_description}
        logoUrl={registerInfo.app_logo}
        onRegister={() => window.location.reload()}
        />;
    } else if (isLoading) {
        element = <div className="flex justify-center items-center h-screen">
            <span className="loading loading-spinner loading-lg"></span>
        </div>;
    } else if (error) {
        // element = <div className="alert alert-error m-4">{error}</div>;
    } else if (!app) {
        element =  <div className="alert alert-error m-4">App not found</div>;
    }



  return (
    <AppContext.Provider value={{
        app,
        setApp,
        updateApp,
        isLoading,
        integrationsSDK,
        entitiesSDK,
        userInfo,
        updateUserInfo,
        error,
        appErrors,
        onAppError,
        addChatMessage,
        currentPage,
        makePageChanges,
        editChatMessage
      }}>
        {element}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);