import React from 'react';
import { default as ReactSelect } from 'react-select';

const Select = ({ value, onChange, options, isMulti, placeholder, optionsNames = null }) => {
    // format options - if option is an object, use the label and value, otherwise use the option
    const formattedOptions = options.map((option, idx) => {
        if (typeof option === 'object') {
            return { label: option.label || optionsNames[idx], value: option.value };
        }
        return { label: optionsNames ? optionsNames[idx] : option, value: option };
    });

    let formattedValue;
    if (value && isMulti) {
        formattedValue = value?.map((v) => formattedOptions.find((option) => option.value === v));
    } else {
        formattedValue = formattedOptions.find((option) => option.value === value);
    }

    const handleChange = (value) => {
        if (isMulti) {
            onChange(value?.map((v) => v.value) || null);
        } else {
            onChange(value?.value || null);
        }
    }

    return (
        <div className="select-wrapper">
            <ReactSelect
                value={formattedValue}
                onChange={handleChange}
                options={formattedOptions}
                isMulti={isMulti}
                placeholder={placeholder}
                className="select-container"
                classNamePrefix="select"
                isClearable={true}
            />
        </div>
    );
};

export default Select;
