import React, { useState, useEffect } from 'react';

const Tabs = ({ defaultActiveKey, items, onChange }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey || items[0].key);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTabClick = (key) => {
    setActiveKey(key);
    if (onChange) {
      onChange(key);
    }
  };

  return (
    <div className="h-full base44-tabs flex flex-col">
      {isMobile ? (
        <select
          className="select select-bordered w-full"
          value={activeKey}
          onChange={(e) => handleTabClick(e.target.value)}
        >
          {items.map((item) => (
            <option key={item.key} value={item.key}>
              {item.label}
            </option>
          ))}
        </select>
      ) : (
        <div role="tablist" className="tabs tabs-bordered">
          {items.map((item) => (
            <a
              role="tab"
              key={item.key}
              className={`tab ${activeKey === item.key ? 'tab-active' : ''} text-xs flex items-center`}
              onClick={() => handleTabClick(item.key)}
            >
              {item.icon && <span className="me-2 text-xs">{item.icon}</span>}
              <span>{item.label}</span>
            </a>
          ))}
        </div>
      )}
      <div className="flex-grow relative">
        {items.find(item => item.key === activeKey)?.component}
      </div>
    </div>
  );
}

export default Tabs;
