import { BaseAPIModelProvider } from './base.ts'

class DataImporterAPI extends BaseAPIModelProvider {
    getBaseURL() {
        return '/data-importers'
    }

    addMessage(itemId, message) {
        return this.axios.post(`/${itemId}/chat/message`, message)
    }

    editMessage(itemId, messageId, message) {
        return this.axios.put(`/${itemId}/chat/message/${messageId}`, message)
    }    

    submitUserInputTool(itemId, toolCallId, values) {
        return this.axios.post(`/${itemId}/chat/submit-user-input-tool`, { tool_call_id: toolCallId, values })
    }
}


export default new DataImporterAPI();