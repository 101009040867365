import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import AuthAPI from '../api/AuthAPI';
import toast from 'react-hot-toast';

function UserSettings() {
    const { user, updateUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [fullName, setFullName] = useState(user.full_name || '');
    const [phoneNumber, setPhoneNumber] = useState(user.phone_number);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const updatedUser = await AuthAPI.update(user.id, { 
                full_name: fullName.length > 0 ? fullName : null, 
                phone_number: phoneNumber.length > 0 ? phoneNumber : null  
            });
            updateUser(updatedUser);
            toast.success('User settings updated successfully!');
        } catch (error) {
            toast.error('Failed to update user settings: ' + error.detail);
        } finally {
            setLoading(false);
        }
    };

    const regenerateApiKey = async () => {
        setLoading(true);
        try {
            const updatedUser = await AuthAPI.regenerateApiKey(user.id);
            updateUser(updatedUser);
            toast.success('API key regenerated successfully!');
        } catch (error) {
            toast.error('Failed to regenerate API key: ' + error.detail);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="p-8 min-h-screen">
            <h2 className="text-2xl font-bold mb-8 text-center">User Settings</h2>
            <div className="max-w-2xl mx-auto">
                <div className="card bg-base-100 shadow-xl">
                    <div className="card-body">
                        <form onSubmit={onSubmit} className="space-y-4">
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Username</span>
                                </label>
                                <input type="text" value={user.username} disabled className="input input-bordered" />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <input type="email" value={user.email} disabled className="input input-bordered" />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Phone Number</span>
                                </label>
                                <input type="text" value={user.phone_number} onChange={(e) => setPhoneNumber(e.target.value)} className="input input-bordered" />
                                <p className="label-text break-words">Optional: Enter phone number to receive SMS notifications.<br/>
By entering your phone number, you agree to receive SMS notifications from us.<br/>
You can opt out at any time - just remove the number.
                                </p>
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">API Key</span>
                                </label>
                                <div className="flex space-x-2">
                                    <input type="text" value={user.api_key} disabled className="input input-bordered flex-grow" />
                                    <button type="button" onClick={regenerateApiKey} className={`btn btn-primary ${loading ? 'loading' : ''}`} disabled={loading}>
                                        Regenerate
                                    </button>
                                </div>
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Full Name</span>
                                </label>
                                <input 
                                    type="text" 
                                    value={fullName} 
                                    onChange={(e) => setFullName(e.target.value)} 
                                    required 
                                    className="input input-bordered" 
                                />
                            </div>
                            <div className="card-actions justify-end">
                                <button type="submit" className={`btn btn-primary ${loading ? 'loading' : ''}`} disabled={loading}>
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSettings;
