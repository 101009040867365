import { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { MessageCircle, Palette, X, ChevronDown, ChevronUp, Code } from 'lucide-react'
import Tabs from '../../Tabs'
import AppChat from '../AppChat'
import Themes from '../../ThemePicker'
import { useApp } from '../../../context/AppContext'
import AppCodeEditor from './AppCodeEditor'
import UserAppAPI from '../../../api/UserAppAPI'

export default function AppSidebar() {
    const { app, updateApp, currentPage, makePageChanges } = useApp();
  const [isOpen, setIsOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [selectedTab, setSelectedTab] = useState('chat');

  useEffect(() => setMounted(true), [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTabChange = (value) => {
    setSelectedTab(value);
    setIsExpanded(value === 'code')
  }

  if (!mounted) return null;

  const manualEditPageCode = (code) => {
    UserAppAPI.manualEditPageCode(app.id, currentPage.id, code);
  }

  const tabs = [
    {
        key: 'chat',
      label: 'Chat',
      icon: <MessageCircle />,
      component: <AppChat />
    },
    {
        key: 'theme',
      label: 'Theme',
      icon: <Palette />,
      component: <Themes value={app.theme} onChange={(t) => updateApp({ theme: t })} />
    },
    {
        key: "code",
        label: "Code",
        icon: <Code />,
        component: <AppCodeEditor 
            code={currentPage.code} 
            onSaveCode={manualEditPageCode}
        />
    }
  ]

  const sidebarWidth = selectedTab === 'code'
    ? isMobile
      ? 'calc(100vw - 1rem)'
      : '600px'
    : '22rem';

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.8, y: 20 }}
            animate={{ 
              opacity: 1, 
              scale: 1, 
              y: 0, 
              height: isExpanded ? 'calc(100vh - 6rem)' : '30rem',
              width: sidebarWidth,
            }}
            exit={{ opacity: 0, scale: 0.8, y: 20 }}
            transition={{ duration: 0.3 }}
            className="bg-background text-foreground rounded-lg shadow-xl border border-black flex flex-col overflow-hidden"
          >
            <div className="bg-primary text-primary-foreground p-4 flex justify-between items-center">
              <h2 className="text-lg font-semibold">Edit App</h2>
              <div className="flex items-center space-x-2">
                <button className="btn btn-ghost btn-square btn-sm" onClick={() => setIsOpen(false)} title="Close chat">
                  <X className="h-4 w-4" />
                </button>
              </div>
            </div>
            <div className="flex flex-col flex-grow overflow-hidden  bg-base-100">
              <Tabs items={tabs} onChange={handleTabChange} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="btn btn-circle btn-primary btn-lg shadow-lg"
        >
          <MessageCircle className="h-6 w-6" />
          <span className="sr-only">Open AI Support Chat</span>
        </button>
      )}
    </div>
  )
}