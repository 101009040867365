import React from 'react';

const Checkbox = ({ value, onChange }) => {
  const _onChange = (e) => {
    console.log("CHECKBOX ONCHANGE", e.target.checked);
    onChange(e.target.checked);
  };
  return (
    <div className="form-control">
        <input
          type="checkbox"
          checked={value}
          onChange={_onChange}
          className="toggle"
        />
    </div>
  );
};

export default Checkbox;
