import React from 'react';
import EntityView from './EntityView';
import ReadableJSONSchema from '../../ReadableJSONSchema';
import { Info } from 'lucide-react';


export default function AppEntities({ app }) {
    
    return (
        <div>
            <div className="card bg-base-100 shadow-xl mb-4">
                <div className="card-body">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-2xl font-semibold">User Data</h3>
                        <div className="dropdown dropdown-hover dropdown-end">
                            <label tabIndex={0}>
                                <Info className="h-5 w-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
                            </label>
                            <div tabIndex={0} className="dropdown-content z-[1] card card-compact w-64 p-2 shadow bg-primary text-primary-content">
                                <div className="card-body">
                                    <p>Data stored for each user of the app</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-gray-600 mb-4">
                        This schema defines the additional information stored for each user of the app.
                    </p>
                    <div className="mt-4">
                        {app.additional_user_data_schema && Object.keys(app.additional_user_data_schema?.properties || {}).length > 0 ? (
                            <ReadableJSONSchema 
                                schema={app.additional_user_data_schema} 
                            />
                        ) : (
                            <p className="text-gray-500 italic">No additional user data schema defined.</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="mt-8">
                {app.entities.map((entity) => (
                    <EntityView key={entity.name} app={app} entity={entity} />
                ))}
            </div>
        </div>
    );
}