import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay, isWithinInterval, startOfWeek, endOfWeek } from 'date-fns';

const Calendar = ({ mode = 'single', selected, onSelect, className = '', isDisabled = null }) => {
  const [currentMonth, setCurrentMonth] = React.useState(new Date());

  const days = React.useMemo(() => {
    const start = startOfWeek(startOfMonth(currentMonth));
    const end = endOfWeek(endOfMonth(currentMonth));
    return eachDayOfInterval({ start, end });
  }, [currentMonth]);

  const isDateDisabled = (date) => {
    return isDisabled ? isDisabled(date) : false;
  };

  const handleDateClick = (date) => {
    if (isDateDisabled(date)) return;

    if (mode === 'single') {
      onSelect(date);
    } else if (mode === 'range') {
      if (!selected?.from) {
        onSelect({ from: date, to: null });
      } else if (selected.from && !selected.to) {
        const [start, end] = [selected.from, date].sort((a, b) => a.getTime() - b.getTime());
        onSelect({ from: start, to: end });
      } else {
        onSelect({ from: date, to: null });
      }
    }
  };

  const isSelected = (date) => {
    if (mode === 'single') {
      return isSameDay(date, selected);
    } else if (mode === 'range') {
      return (
        (selected?.from && isSameDay(date, selected.from)) ||
        (selected?.to && isSameDay(date, selected.to)) ||
        (selected?.from &&
          selected?.to &&
          isWithinInterval(date, { start: selected.from, end: selected.to }))
      );
    }
    return false;
  };

  return (
    <div className={`p-4 ${className}`}>
      <div className="flex items-center justify-between mb-4">
        <button
          onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
          className="btn btn-circle btn-sm"
        >
          <ChevronLeft size={20} />
        </button>
        <h2 className="text-lg font-semibold">
          {format(currentMonth, 'MMMM yyyy')}
        </h2>
        <button
          onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
          className="btn btn-circle btn-sm"
        >
          <ChevronRight size={20} />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="text-center font-medium text-sm py-2">
            {day}
          </div>
        ))}
        {days.map((day) => (
          <button
            key={day.toString()}
            onClick={() => handleDateClick(day)}
            disabled={isDateDisabled(day)}
            className={`btn btn-sm ${
              !isSameMonth(day, currentMonth)
                ? 'btn-ghost opacity-40'
                : isSelected(day)
                ? 'btn-primary'
                : 'btn-outline'
            } ${isDateDisabled(day) ? 'btn-disabled' : ''}`}
          >
            {format(day, 'd')}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Calendar;
export { Calendar };
