import './index.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext.js';
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import PageNotFound from './pages/PageNotFound.js';
import { pages } from './pages/index.js';
import Sidebar from './components/Sidebar.js';
import _ from 'lodash';
import './App.css'
import { BACKEND_URL } from './config.js';
import { useParams } from 'react-router-dom';
import Loader from './components/Loader.js';
import { Toaster } from 'react-hot-toast';
import PrivacyPolicy from "./pages/legal/PrivacyPolicy.js";
import TermsOfService from "./pages/legal/TermsOfService.js";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoadingAuth } = useAuth();

  if (isLoadingAuth) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader />
      </div>
    );
  }
  
  if (!isAuthenticated) {
    const currentUrl = encodeURIComponent(window.location.href);
    return <Navigate to={`/login?from_url=${currentUrl}`} replace />;
  }
  
  return children;
};

function NavigateToFile(){
  const { fileId } = useParams();
  window.location.href = `${BACKEND_URL}/api/files/${fileId}`;
}

const App = () => {
  const flatPages = _.flatMapDeep(pages, page => [page, ...(page.children || [])]);
  return (
    <div className="font-sans">
      <div><Toaster/></div>

      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            {flatPages.map(p => 
              p.usesLayout === false ? (
                <Route key={p.url} path={p.url} element={<ProtectedRoute>{p.component}</ProtectedRoute>} />
              ) : (
                <Route key={p.url} path={p.url} element={
                  <div className="flex h-screen">
                    <Sidebar pages={pages.filter(p => p.showInMenu !== false)} className="fixed h-full overflow-y-auto" />
                    <div className="flex-1 overflow-auto"> 
                      <main>
                        <ProtectedRoute>{p.component}</ProtectedRoute>
                      </main>
                    </div>
                  </div>
                } />
              )
            )}
            <Route path="/api/files/:fileId" element={<NavigateToFile />} replace />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;