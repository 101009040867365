import React from 'react';
import moment from 'moment';
import * as momentArabic from 'moment/locale/ar';
import * as momentFrench from 'moment/locale/fr';
import * as momentSpanish from 'moment/locale/es';
import * as momentHebrew from 'moment/locale/he';

import * as ReactHotToast from 'react-hot-toast';
import * as Recharts from 'recharts';
import * as LucideReact from 'lucide-react';
import Runner from './Runner';

import JsonSchemaForm from '../../lib/components/json-schema-form';
import Calendar from '../../lib/components/Calendar';
import AIChat from '../../pages/AppUserFacingChat'; 

moment.locale('en-US');

export default function ComponentPreview({ componentCode, componentProps, onError }) {    
  const newConsole = {
    log: (...args) => {
      console.log("Component Logger:", ...args);
    },
    error: (...args) => {
      onError(args.map(arg => String(arg)).join(' '));
      console.error("Component Logger:", ...args);
    },
    warn: (...args) => {
      console.warn("Component Logger:", ...args);
    }
  }
  
    const scope = { 
      "console": newConsole,

      import: {
        'recharts': Recharts,
        'react-hot-toast': ReactHotToast,
        'moment': moment,
        'moment/locale/he': momentHebrew,
        'moment/locale/ar': momentArabic,
        'moment/locale/fr': momentFrench,
        'moment/locale/es': momentSpanish,
        'react': React,
        'lucide-react': LucideReact,
        '@/components/calendar': Calendar,
        '@/components/json-schema-form': JsonSchemaForm,
        '@/components/ai-chat': AIChat
      }
     }
  
    return <div>
        <Runner code={componentCode} scope={scope} componentProps={componentProps} onError={onError} />
        </div>
  }