import React, { useState, useEffect } from 'react';
import { useApp } from '../../../context/AppContext';
import JsonSchemaForm from '../../../lib/components/json-schema-form';
import ReadableJSONSchema from '../../ReadableJSONSchema';
import Tabs from '../../Tabs';
import JsonSchemaTable from '../../JsonSchemaTable';

function EntitySchema({ entity }) {
    const {app, updateApp} = useApp();

    const onEdit = (name, prop) => {
        entity.properties[name] = prop;
        app.entities = app.entities.map(e => e.name === entity.name ? entity : e);
        updateApp({entities: app.entities});
    };

    
    return (
        <ReadableJSONSchema schema={entity} onEdit={onEdit} />
    );
}


function EntityView({ entity }) {
    const { entitiesSDK } = useApp();
    const [entities, setEntities] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingItem, setEditingItem] = useState(null);

    useEffect(() => {
        entitiesSDK[entity.name].list().then((entities) => {
            setEntities(entities);
        });
    }, []);

    const handleCreateNewItem = async (values) => {
        try {
            const newItem = await entitiesSDK[entity.name].create(values);
            setEntities([...entities, newItem]);
            setIsModalVisible(false);
        } catch (error) {
            setErrors([...errors, error]);
        }
    };

    const handleEditItem = (item, field, newValue) => {
        let updatedItem = item;
        if (field){ 
            updatedItem = { ...item, [field]: newValue };
        }
        
        entitiesSDK[entity.name].update(item.id, updatedItem)
            .then(updatedEntity => {
                setEntities(entities.map(entity => entity.id === updatedEntity.id ? updatedEntity : entity));
            })
            .catch(error => {
                setErrors([...errors, error]);
                alert('Failed to update item');
            });
    };

    const handleUpdateItem = async (values) => {
        try {
            const updatedItem = await entitiesSDK[entity.name].update(editingItem.id, values);
            setEntities(entities.map(item => item.id === editingItem.id ? updatedItem : item));
            setIsModalVisible(false);
            setEditingItem(null);
        } catch (error) {
            setErrors([...errors, error]);
        }
    };

    const handleDeleteItem = async (item) => {
        try {
            await entitiesSDK[entity.name].delete(item.id);
            setEntities(entities.filter(entity => entity.id !== item.id));
            alert('Item deleted successfully');
        } catch (error) {
            setErrors([...errors, error]);
            alert('Failed to delete item');
        }
    };

    const tabItems = [
        {
            key: "entity-definition",
            label: "Fields",
            component: <EntitySchema entity={entity} />
        },
        {
            key: "records",
            label: "Records",
            component: (
                <>
                    <div className="overflow-x-auto">
                        <JsonSchemaTable 
                            jsonSchema={entity} 
                            data={entities} 
                            onDataChange={handleEditItem}
                        />
                    </div>
                    <button 
                        className="btn btn-primary mt-6"
                        onClick={() => setIsModalVisible(true)}
                    >
                        Add New Item
                    </button>
                </>
            )
        }
    ];

    return (
        <>
            <div className="card bg-base-100 shadow-xl mb-4">
                <div className="card-body">
                    <h2 className="card-title">{entity.name}</h2>
                    <p className="text-gray-600">{entity.description}</p>
                    <Tabs items={tabItems} />
                </div>
            </div>
            {isModalVisible && (
                <div className="modal modal-open">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">
                            {editingItem ? `Edit ${entity.name}` : `Add New ${entity.name}`}
                        </h3>
                        <JsonSchemaForm 
                            schema={entity} 
                            onSubmit={editingItem ? () => handleUpdateItem(editingItem) : handleCreateNewItem} 
                            initialValues={editingItem}
                        />
                        <div className="modal-action">
                            <button className="btn" onClick={() => {
                                setIsModalVisible(false);
                                setEditingItem(null);
                            }}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default EntityView;