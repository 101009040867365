import { useEffect, useState   } from "react";
import { useNavigate } from "react-router-dom";
import DataImporterAPI from "../../api/DataImporterAPI";
import { Link } from "react-router-dom";




export default function DataImporterDashboard() {
    const [dataImporters, setDataImporters] = useState([]);
    const [newChatMessage, setNewChatMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchDataImporters = async () => {
            const importers = await DataImporterAPI.list();
            setDataImporters(importers);
        };
        fetchDataImporters();
    }, []);

    const handleNewChat = async (e) => {
        e.preventDefault();
        if (newChatMessage.trim()) {
            try {
                const newImporter = await DataImporterAPI.create({});
                DataImporterAPI.addMessage(newImporter.id, { content: newChatMessage });
                navigate(`/data-importer/${newImporter.id}`);
            } catch (error) {
                console.error("Error creating new data importer:", error);
                // You might want to show an error message to the user here
            }
        }
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Data Importer Dashboard</h1>
            
            <form onSubmit={handleNewChat} className="mb-8">
                <textarea
                    className="w-full p-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    rows="3"
                    placeholder="Start a new data importer chat..."
                    value={newChatMessage}
                    onChange={(e) => setNewChatMessage(e.target.value)}
                ></textarea>
                <button 
                    type="submit" 
                    className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                    Start New Chat
                </button>
            </form>

            <h2 className="text-xl font-semibold mb-4">Existing Data Importers</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {dataImporters.map((importer) => (
                    <div key={importer.id} className="border rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow">
                        <h3 className="font-bold mb-2">{importer.name}</h3>
                        <p className="text-sm text-gray-600 mb-2">{importer.description}</p>
                        <p className="text-sm text-gray-600 mb-2">Status: {importer.status?.state}</p>
                        <Link 
                            to={`/data-importer/${importer.id}`}
                            className="text-blue-500 hover:text-blue-700"
                        >
                            View Details
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}