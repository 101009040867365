import { isEmpty } from 'lodash';


export function inferRequiredFields(schema) {
    if (!schema || isEmpty(schema)){
        throw new Error("JSON Schema error is required, got: " + JSON.stringify(schema));
    }
    if (isEmpty(schema.properties)){
        throw new Error("JSON Schema error: properties are required in schema, got schema: " + JSON.stringify(schema));
    }
    const requiredFields = schema.hasOwnProperty("required") ? schema.required : Object.keys(schema.properties).filter(key => !schema.properties[key].hasOwnProperty('default'));
    return requiredFields;
}