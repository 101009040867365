import React, { useState, useEffect } from 'react';
import { User, Image as ImageIcon, Send, ExternalLink, Trash2, Globe, Copy } from 'lucide-react';
import UserAppAPI from '../../../api/UserAppAPI';
import InviteUserModal from './../InviteUserModal';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useApp } from '../../../context/AppContext';
import AppAPI from './AppAPI';

const AppSettings = () => {
  const {app, entitiesSDK} = useApp();
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [appName, setAppName] = useState(app.name);
  const [appDescription, setAppDescription] = useState(app.user_description);
  const [isUpdateNameOrDescription, setIsUpdateNameOrDescription] = useState(false);
  const [isGeneratingLogo, setIsGeneratingLogo] = useState(false);
  const [userFacingChatPrompt, setUserFacingChatPrompt] = useState(app.user_facing_chat_system_prompt || '');
  const [isUpdatingChatPrompt, setIsUpdatingChatPrompt] = useState(false);
  const [appLanguage, setAppLanguage] = useState(app.language || 'english');
  const [isUpdatingLanguage, setIsUpdatingLanguage] = useState(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [isCloningApp, setIsCloningApp] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [app.id]);

  const fetchUsers = async () => {
    try {
      const response = await entitiesSDK.User.list();
      setUsers(response);
    } catch (error) {
      toast.error('Failed to fetch users');
    }
  };

  const handleRoleChange = async (user) => {
    try {
      await UserAppAPI.updateUserRole(app.id, user.email, 'admin');
      toast.success(`${user.email} is now an admin`);
      fetchUsers();
    } catch (error) {
      toast.error('Failed to update user role');
    }
  };

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  const handleInviteSuccess = () => fetchUsers();

  const handleNameChange = (e) => setAppName(e.target.value);
  const handleDescriptionChange = (e) => setAppDescription(e.target.value);
  const handleLanguageChange = (e) => setAppLanguage(e.target.value);

  const handleUpdateApp = async () => {
    try {
      setIsUpdateNameOrDescription(true);
      await UserAppAPI.update(app.id, { name: appName, user_description: appDescription });
      toast.success('App details updated successfully');
    } catch (error) {
      toast.error('Failed to update app details');
    } finally {
      setIsUpdateNameOrDescription(false);
    }
  };

  const handleUpdateLanguage = async () => {
    try {
      setIsUpdatingLanguage(true);
      await UserAppAPI.update(app.id, { language: appLanguage });
      toast.success('App language updated successfully');
    } catch (error) {
      toast.error('Failed to update app language');
    } finally {
      setIsUpdatingLanguage(false);
    }
  };

  const handleRegenerateLogo = async () => {
    try {
      setIsGeneratingLogo(true);
      await UserAppAPI.generateLogo(app.id);
      toast.success('Logo regenerated successfully');
    } catch (error) {
      toast.error('Failed to regenerate logo');
    } finally {
      setIsGeneratingLogo(false);
    }
  };

  const handleUserFacingChatPromptChange = (e) => setUserFacingChatPrompt(e.target.value);

  const handleUpdateUserFacingChatPrompt = async () => {
    try {
      setIsUpdatingChatPrompt(true);
      await UserAppAPI.update(app.id, { user_facing_chat_system_prompt: userFacingChatPrompt });
      toast.success('User facing chat prompt updated successfully');
    } catch (error) {
      toast.error('Failed to update user facing chat prompt');
    } finally {
      setIsUpdatingChatPrompt(false);
    }
  };

  const showDeleteConfirm = () => {
    if (window.confirm('Are you sure you want to delete this app? This action cannot be undone.')) {
      handleDeleteApp();
    }
  };

  const handleDeleteApp = async () => {
    try {
      await UserAppAPI.delete(app.id);
      toast.success('App deleted successfully');
      navigate('/');
    } catch (error) {
      toast.error('Failed to delete app');
    }
  };

  const handleAppPublish = async () => {
    try {
      setIsLoadingPublish(true);
      if (app.app_publish_info === null) {
        await UserAppAPI.publishApp(app.id);
        toast.success('App published successfully');
      } else {
        await UserAppAPI.unpublishApp(app.id);
        toast.success('App unpublished successfully');
      }
    } catch (error) {
      toast.error('Failed to update app');
    } finally {
      setIsLoadingPublish(false);
    }
  };

  const handleCloneApp = async () => {
    try {
      setIsCloningApp(true);
      const newApp = await UserAppAPI.cloneApp(app.id);
      toast.success('App cloned successfully');
      navigate(`/apps/${newApp.id}/dashboard`);
    } catch (error) {
      toast.error('Failed to clone app');
    } finally {
      setIsCloningApp(false);
    }
  };

  return (
    <div className="container mx-auto p-4 space-y-8">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title text-2xl font-bold">App Settings</h2>
          
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">App Details</h3>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Name</span>
              </label>
              <input
                type="text"
                value={appName}
                onChange={handleNameChange}
                className="input input-bordered w-full"
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Description</span>
              </label>
              <textarea
                value={appDescription}
                onChange={handleDescriptionChange}
                className="textarea textarea-bordered h-24"
                placeholder="App Description"
              ></textarea>
            </div>
            <button 
              onClick={handleUpdateApp} 
              className={`btn btn-primary ${isUpdateNameOrDescription ? 'loading' : ''}`}
              disabled={isUpdateNameOrDescription}
            >
              Update App Details
            </button>
          </div>

          <div className="divider"></div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">App Language</h3>
            <div className="form-control">
              <label className="label">
                <span className="label-text">Language</span>
              </label>
              <select
                value={appLanguage}
                onChange={handleLanguageChange}
                className="select select-bordered w-full"
              >
                <option value="english">English</option>
                <option value="french">French</option>
                <option value="spanish">Spanish</option>
                <option value="arabic">Arabic</option>
                <option value="hebrew">Hebrew</option>
              </select>
            </div>
            <button 
              onClick={handleUpdateLanguage} 
              className={`btn btn-primary ${isUpdatingLanguage ? 'loading' : ''}`}
              disabled={isUpdatingLanguage}
            >
              <Globe className="mr-2 h-4 w-4" />
              Update App Language
            </button>
          </div>

          <div className="divider"></div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">App Logo</h3>
            {app.logo_url && (
              <div className="avatar">
                <div className="w-24 rounded">
                  <img src={app.logo_url} alt="App Logo" />
                </div>
              </div>
            )}

            <div className='mt-2'>
            <button 
              onClick={handleRegenerateLogo} 
              className={`btn btn-outline ${isGeneratingLogo ? 'loading' : ''}`}
              disabled={isGeneratingLogo}
            >
              <ImageIcon className="mr-2 h-4 w-4" />
              Regenerate Logo
            </button>
            </div>
          </div>

          <div className="divider"></div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">User Management</h3>
            <button onClick={showModal} className="btn btn-primary">
              <User className="mr-2 h-4 w-4" />
              Invite User
            </button>
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Role</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <div className="flex flex-col">
                          <span className="font-bold">{user.name}</span>
                          <span className="text-sm opacity-50">{user.email}</span>
                        </div>
                      </td>
                      <td>
                        <span className={`badge ${user._app_role === 'admin' ? 'badge-primary' : 'badge-secondary'}`}>
                          {user._app_role}
                        </span>
                      </td>
                      <td>
                        <button 
                          onClick={() => handleRoleChange(user)}
                          className="btn btn-ghost btn-xs"
                          disabled={user._app_role === 'admin'}
                        >
                          Make Admin
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="divider"></div>
          <div className="space-y-4">
            <h3 className="text-xl font-semibold">App Publication</h3>
            {app.app_publish_info === null ? (
              <button 
                onClick={handleAppPublish} 
                className={`btn btn-primary ${isLoadingPublish ? 'loading' : ''}`}
                disabled={isLoadingPublish}
              >
                <Globe className="mr-2 h-4 w-4" />
                Publish App To Marketplace
              </button>
            ) : (
              <button 
                onClick={handleAppPublish} 
                className={`btn btn-secondary ${isLoadingPublish ? 'loading' : ''}`}
                disabled={isLoadingPublish}
              >
                <Globe className="mr-2 h-4 w-4" />
                Unpublish App
              </button>
            )}
            {app.app_publish_info !== null && (
              <p className="text-sm text-success mt-2">
                This app is currently published in the marketplace.
              </p>
            )}
          </div>

          <div className="divider"></div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">User Facing Chat</h3>
            <div className="form-control">
              <label className="label">
                <span className="label-text">System Prompt</span>
              </label>
              <textarea
                value={userFacingChatPrompt}
                onChange={handleUserFacingChatPromptChange}
                className="textarea textarea-bordered h-24"
                placeholder="Enter the system prompt for user facing chat"
              ></textarea>
            </div>
            <button 
              onClick={handleUpdateUserFacingChatPrompt} 
              className={`btn btn-primary ${isUpdatingChatPrompt ? 'loading' : ''}`}
              disabled={isUpdatingChatPrompt}
            >
              <Send className="mr-2 h-4 w-4" />
              Update User Facing Chat Prompt
            </button>
            <Link to={`/apps/${app.id}/chat`} target="_blank" rel="noopener noreferrer" className="btn btn-outline">
              <ExternalLink className="mr-2 h-4 w-4" />
              Open Chat in New Tab
            </Link>
          </div>

          <div className="divider"></div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Clone App</h3>
            <button 
              onClick={handleCloneApp} 
              className={`btn btn-primary ${isCloningApp ? 'loading' : ''}`}
              disabled={isCloningApp}
            >
              <Copy className="mr-2 h-4 w-4" />
              Clone App
            </button>
          </div>

          <div className="divider"></div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold text-error">Danger Zone</h3>
            <button onClick={showDeleteConfirm} className="btn btn-error">
              <Trash2 className="mr-2 h-4 w-4" />
              Delete App
            </button>
          </div>
        </div>
      </div>

      <InviteUserModal
        isVisible={isModalVisible}
        onCancel={handleCancel}
        appId={app.id}
        onInviteSuccess={handleInviteSuccess}
      />

      <AppAPI />
    </div>
  );
};

export default AppSettings;
