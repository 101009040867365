import React from 'react';
import Markdown from '../../Markdown';


export default function AppHeader({ app, onOpenApp, onShareApp }) {
    return (
        <div className="mb-6">
            <div className="flex items-center mb-2">
                {app.logo_url && (
                    <img
                        src={app.logo_url}
                        alt={`${app.name} logo`}
                        className="w-16 rounded-full object-cover mr-4 border"
                    />
                )}
                <h2 className="text-2xl font-bold m-0 flex items-center ms-2">{app.name}</h2>
            </div>
            <p className="text-sm text-gray-600 mb-4 line-clamp-2 hover:line-clamp-none">
                {app.user_description}
            </p>
            {app.status.state === "processing" ? (
                <div className="flex items-center">
                    <span className="loading loading-spinner loading-md"></span>
                    <div className="text-gray-500 ml-2">{app.status.details}</div>
                </div>
            ) : (
                <div>
                    <button 
                        className="btn btn-primary me-2"
                        onClick={onOpenApp}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                        </svg>
                        Open App
                    </button>
                    <button
                        className="btn btn-outline"
                        onClick={onShareApp}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                        </svg>
                        Share App
                    </button>
                </div>
            )}
        </div>
    );
}