import React from 'react';
import UserAppAPI from '../../api/UserAppAPI';
import JsonSchemaForm from '../../lib/components/json-schema-form/JsonSchemaForm';

const InviteUserModal = ({ isVisible, onCancel, appId, onInviteSuccess }) => {
  const handleInvite = async (values) => {
    try {
      await UserAppAPI.inviteUser(appId, values.email, values.role);
      alert('Invitation sent successfully');
      onInviteSuccess();
      onCancel();
    } catch (error) {
      alert('Failed to send invitation');
    }
  };

  const schema = {
    type: 'object',
    properties: {
      email: {
        type: 'string',
        format: 'email',
        title: 'Email',
        placeholder: 'Enter email'
      },
      role: {
        type: 'string',
        enum: ['user', 'admin'],
        title: 'Role'
      }
    },
    required: ['email', 'role']
  };

  return (
    <div className={`modal ${isVisible ? 'modal-open' : ''}`}>
      <div className="modal-box">
        <h3 className="font-bold text-lg">Invite User</h3>
        <JsonSchemaForm
          schema={schema}
          onSubmit={handleInvite}
        />
        <div className="modal-action">
          <button className="btn" onClick={onCancel}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default InviteUserModal;
