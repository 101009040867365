import Markdown from '../../components/Markdown';

const PolicyText = `
# Privacy Policy for base44

*Effective Date: 2024-07-20*

At base44, your privacy is our priority. This Privacy Policy explains how we collect, use, and protect your personal information when you use our productivity application.

## 1. Information We Collect

### Personal Information
- **Account Details**: When you create an account, we collect your name, email address, and password.

### Calendar Data
- **Calendar Integration**: If you choose to integrate your calendar, we access your calendar events to provide notifications about your daily activities.

### Usage Data
- **App Interaction**: We collect information on how you use base44 to improve our services.

## 2. How We Use Your Information

### Providing Services
- **Service Delivery**: We use your information to operate and maintain base44, ensuring you receive the best possible experience.

### Calendar Notifications
- **Daily Activities**: Your calendar data is used exclusively to send you notifications about your scheduled events and tasks.

### Communication
- **Updates and Support**: We may send you emails or in-app messages regarding updates, security alerts, or customer support inquiries.

## 3. Calendar Integration

Your calendar information is accessed only with your explicit permission. We do not use your calendar data for any purpose other than to provide you with notifications and assist in managing your daily activities. We do not share, sell, or disclose your calendar information to third parties.

## 4. Data Security

We implement industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.

## 5. Data Retention

We retain your personal information only as long as necessary to provide you with our services or as required by law.

## 6. Your Rights

- **Access and Correction**: You can access and update your personal information through your account settings.
- **Deletion**: You may request the deletion of your account and personal data at any time.

## 7. Changes to This Privacy Policy

We may update this Privacy Policy periodically. We will notify you of any significant changes by posting the new Privacy Policy on this page.

## 8. Contact Us

If you have any questions or concerns about this Privacy Policy, please contact us at info@base44.com.

---

By using base44, you agree to the collection and use of your information in accordance with this Privacy Policy.
`;


export default function PrivacyPolicy() {
    return <div className="p-9"><Markdown>{PolicyText}</Markdown></div>;
}