import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import JsonSchemaForm from '../lib/components/json-schema-form';
import { AppProvider, useApp } from '../context/AppContext';
import { toast } from 'react-hot-toast';
import AppDemo from '../components/user-app/demo/AppDemo';
import AppSidebar from '../components/user-app/user-app-sidebar/AppSidebar';

function UserDataFormModal() {
    const {app, userInfo, updateUserInfo} = useApp();
    const onSubmit = (userData) => {
        updateUserInfo(userInfo.id, userData);
    }
    return (
        <dialog open className="modal">
            <div className="modal-box">
                <h3 className="font-bold text-lg">Fill in required user data</h3>
                <JsonSchemaForm schema={app.additional_user_data_schema} initialValues={userInfo.app_data} onSubmit={onSubmit} />
            </div>
        </dialog>
    );
}

function UserAppDemo() {
    const { app, userInfo } = useApp();
    const [showUserDataFormModal, setShowUserDataFormModal] = useState(false);
    const [sidebarVisible] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });

    function shouldShowUserDataFormModal(app, userInfo) {
        if (!app || !userInfo) {
            return false;
        }
        const requiredFields = app.required_user_data_fields;
        console.log("requiredFields", requiredFields);
        if (!requiredFields || !userInfo) {
            return false;
        }

        const missingFields = requiredFields.filter(field => !userInfo.hasOwnProperty(field) || userInfo[field] === null || userInfo[field] === undefined);
        if (missingFields.length > 0) {
            console.log("user is missing data fields: " + missingFields.join(', ') + " so showing a form to fill it in before showing the app");
        }
        return missingFields.length > 0;
    }

    useEffect(() => {
        if (shouldShowUserDataFormModal(app, userInfo)) {
            setShowUserDataFormModal(true);
        } else {
            setShowUserDataFormModal(false);
        }
    }, [app, userInfo]);

    const handleShareApp = () => {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
            toast.success('App link copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
            toast.error('Failed to copy app link');
        });
    };

    
    if (showUserDataFormModal) {
        return <UserDataFormModal />;
    }

    return (
        <div className="flex flex-col md:flex-row" data-theme={app.theme === 'default' ? null : app.theme}>
            <div className="flex-grow">
                <AppDemo />
                <AppSidebar />
            </div>
            {sidebarVisible && !isMobile && (
                <aside 
                    className="w-[450px] p-4 border-l border-gray-300 bg-base-200 z-50 fixed top-0 right-0 h-screen overflow-y-auto"
                >
                    <AppSidebar />
                </aside>
            )}
        </div>
    );
}

export default () => <AppProvider>
    <UserAppDemo />
</AppProvider>;