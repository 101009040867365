import axios, { AxiosError } from 'axios'
import _ from 'lodash'
import { API_BASE_URL } from '../config'
import socketManager from './socket-manager.ts'
import { toast } from 'react-hot-toast'

let defaultHeaders = {}

export function addDefaultHeader(key: string, value: string) {
    defaultHeaders[key] = value
}

export function removeDefaultHeader(key: string) {
	delete defaultHeaders[key]
}

let failCallbacks = []

export function addFailCallback(callback: () => void) {
    failCallbacks.push(callback)
}

export default function createClient(endpointBase: string) {
	const axiosClient = axios.create()
	axiosClient.defaults.baseURL = API_BASE_URL + endpointBase
	axiosClient.defaults.headers.common['Content-Type'] = 'application/json'
	axiosClient.defaults.headers.common['Accept'] = 'application/json'

	axiosClient.interceptors.request.use(
		(config: any) => {
            config.headers = _.merge(config.headers, defaultHeaders)
			config.headers['X-Socket-Id'] = socketManager.getSocketId();
			return config
		},
		(error: AxiosError) => {
			return Promise.reject(error)
		},
	)

	axiosClient.interceptors.response.use(
		(response) => {
			return response.data;
		},
		(error: AxiosError) => {
			for (const callback of failCallbacks) {
				callback(error)
			}
			if (!error.response) {
				console.log("NO ERROR RESPONSE", error);
			}
			else if (error.response?.status === 429) {
				toast.error('Too many requests, please try again later.')
			} else if (error.response?.status === 500) {
				if (error.response.data?.detail) {		
					let msgString = error.response.data.detail;
					if (msgString.length > 100){
						msgString = msgString.substring(0, 100) + '...'
					}
					toast.error('Error: ' + msgString)
				} else {
					toast.error('Error occured')
				}
			} else if (error.response?.status !== 403) {
				toast.error('Error occured')
			}
			return Promise.reject(error);
		}
	);

	return axiosClient
}
