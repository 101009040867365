import React from 'react';
import { Editor } from '@monaco-editor/react';

const CodeEditor = ({ value, onChange, language = 'javascript', readOnly = false, ...props }) => {
  const handleEditorChange = (newValue) => {
    if (onChange && !readOnly) {
      onChange(newValue);
    }
  };

  return (
    
        <Editor
      defaultLanguage={language}
      value={value}
      onChange={handleEditorChange}
      options={{
        minimap: { enabled: false },
        scrollBeyondLastLine: false,
        fontSize: 14,
        readOnly: readOnly,
      }}
      {...props}
    />
    
    
  );
};

export default CodeEditor;

