import React, { useState, useEffect } from 'react';
import { FileText, AppWindow, Code, Settings } from 'lucide-react';
import UserAppAPI from '../api/UserAppAPI';
import AppScript from '../components/user-app/dashboard/AppScript';
import AppSettings from '../components/user-app/dashboard/AppSettings';
import InviteUserModal from '../components/user-app/InviteUserModal';
import { AppProvider, useApp } from '../context/AppContext';
import AppHeader from '../components/user-app/dashboard/AppHeader';
import AppEntities from '../components/user-app/dashboard/AppEntities';
import AppPage from '../components/user-app/dashboard/AppPage';
import { toast } from 'react-hot-toast';
import Tabs from '../components/Tabs';
import AppChat from '../components/user-app/AppChat';

function UserAppDashboard() {
    const { app, setApp } = useApp();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCodePage = async (pageId) => {
        try {
            const updatedApp = await UserAppAPI.codePage(app.id, pageId);
            setApp(updatedApp);
        } catch (err) {
            console.error(err);
            toast.error('Failed to code page: ' + err.message);
        }
    };

    const handleOpenApp = () => {
        window.open(`/apps/${app.id}/show`, '_blank');
    };

    const handleShareApp = () => {
        setIsInviteModalVisible(true);
    };

    const handleInviteSuccess = () => {
        toast.success('User invited successfully');
    };

    const hasEntitiesOrPagesOrScripts = app.entities.length > 0 || app.pages.length > 0 || app.scheduled_actions.length > 0;

    const tabItems = [
        {
            key: 'pages',
            icon: <FileText size={16} />,
            label: `Pages (${app.pages.length})`,
            component: (
                <div className="py-4">
                    {app.pages.map((page) => (
                        <AppPage 
                            key={page.id}
                            page={page}
                            onCodePage={handleCodePage}
                        />
                    ))}
                </div>
            )
        },
        {
            key: 'entities',
            icon: <AppWindow size={16} />,
            label: `Entities (${app.entities.length})`,
            component: <div className="py-4"><AppEntities app={app} /></div>
        },
        {
            key: 'scripts',
            icon: <Code size={16} />,
            label: `Scripts (${app.scheduled_actions.length})`,
            component: (
                <div className="py-4">
                    {app.scheduled_actions.map((script) => (
                        <AppScript key={script.id} appId={app.id} script={script} />
                    ))}
                </div>
            )
        },
        {
            key: 'settings',
            icon: <Settings size={16} />,
            label: 'Settings',
            component: <div className="py-2"><AppSettings app={app} /></div>
        }
    ];

    return (
        <div className={`${isMobile && !hasEntitiesOrPagesOrScripts ? '' : isMobile ? 'px-4' : 'p-16'} flex ${isMobile ? 'flex-col' : 'flex-row'} h-screen relative`}>
            {hasEntitiesOrPagesOrScripts ? (
                <>
                    <div className={isMobile ? 'w-full mb-6 pt-16' : 'w-2/3 pe-6 overflow-y-auto'}>
                        <AppHeader 
                            app={app}
                            onOpenApp={handleOpenApp}
                            onShareApp={handleShareApp}
                        />
                        <div className="mt-4">
                            <Tabs items={tabItems} />
                        </div>
                    </div>
                    <div className={`${isMobile ? 'w-full h-[calc(100vh-24rem)]' : 'w-1/3 ps-3 h-full'} min-h-96`}>
                        <div className="h-full rounded-lg relative">
                            <AppChat />
                        </div>
                    </div>
                </>
            ) : (
                
                <div className={`w-full flex justify-center ${isMobile ? 'h-full' : ''}`}>
                    <div className={`${isMobile ? 'w-full h-full relative' : 'max-w-2xl w-full h-full relative'}`}>
                        <AppChat />
                    </div>
                </div>
            )}
            {isInviteModalVisible && <InviteUserModal
                isVisible={isInviteModalVisible}
                onCancel={() => setIsInviteModalVisible(false)}
                appId={app.id}
                onInviteSuccess={handleInviteSuccess}
            />}
        </div>
    );
}

export default () => (
    <AppProvider>
        <UserAppDashboard />
    </AppProvider>
);
