import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const JsonSchemaTable = ({ jsonSchema, data, onCellChange, editable = false }) => {
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(data);
  }, [data]);

  const columnDefs = useMemo(() => {
    return Object.entries(jsonSchema.properties).map(([key, value]) => {
      const colDef = {
        field: key,
        headerName: value.title || key,
        editable: editable,
      };

      if (value.enum) {
        colDef.cellEditor = 'agSelectCellEditor';
        colDef.cellEditorParams = {
          values: value.enum
        };
      } else if (value.format === 'date') {
        colDef.cellEditor = 'agDateCellEditor';
        colDef.cellEditorParams = {
          useFormatter: true
        };
        colDef.valueFormatter = params => {
          return params.value ? new Date(params.value).toLocaleDateString() : '';
        };
      } else {
        switch (value.type) {
          case 'string':
            colDef.cellEditor = 'agTextCellEditor';
            break;
          case 'number':
            colDef.cellEditor = 'agNumberCellEditor';
            break;
          case 'boolean':
            colDef.cellRenderer = params => 
              <input type='checkbox' checked={params.value} onChange={() => {}} />;
            colDef.cellEditor = 'agCheckboxCellEditor';
            break;
          case 'array':
            colDef.cellEditor = 'agLargeTextCellEditor';
            colDef.cellRenderer = params => params.value ? params.value.join(', ') : '';
            break;
          default:
            colDef.cellEditor = 'agTextCellEditor';
        }
      }

      return colDef;
    });
  }, [jsonSchema, editable]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    resizable: true,
  }), []);

  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onCellValueChanged = params => {
    const updatedData = [...rowData];
    const rowIndex = params.node.rowIndex;
    updatedData[rowIndex] = { ...updatedData[rowIndex], ...params.data };
    setRowData(updatedData);
    onCellChange(params.data, params.colDef.field, params.newValue);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
        animateRows={true}
        enableCellChangeFlash={true}
      />
    </div>
  );
};

export default JsonSchemaTable;
