import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LogIn } from 'lucide-react';
import { API_BASE_URL } from '../config';
import logoImage from '../logo-v1.png';

export default function Login() {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get('access_token');
    if (accessToken) {
      localStorage.setItem('token', accessToken);
      const navigateTo = searchParams.get('from_url') || '/';
      window.location.href = navigateTo;
    }
  }, [location]);

  const handleGoogleLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    const fromUrl = searchParams.get('from_url') || '/';
    window.location.href = `${API_BASE_URL}/auth/login?from_url=${fromUrl}`;
  };

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <div className="text-center lg:text-left">
          <h1 className="text-5xl font-bold">Welcome to base44</h1>
          <p className="py-6">Sign in with your Google account to get started on your journey with base44.</p>
        </div>
        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
          <div className="card-body">
            <div className="flex justify-center mb-8">
              <img src={logoImage} alt="base44 logo" className="w-24 h-24" />
            </div>
            <div className="form-control mt-6">
              <button onClick={handleGoogleLogin} className="btn btn-primary">
                <LogIn className="mr-2 h-5 w-5" />
                Continue with Google
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
