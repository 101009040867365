import createClient from './axiosClient.ts'
import { toast } from 'react-hot-toast';

export default async function createIntegrationsClient(baseUrl, onError) {
    const client = createClient(baseUrl);
    const schema = await client.get('/schema');
    let integrationsClient = {};
    Object.keys(schema).forEach(key => {
        integrationsClient[key] = async (data) => {
            try {
                let formData;
                let contentType;
                if (data instanceof FormData || (data && Object.values(data).some(value => value instanceof File))) {
                    formData = new FormData();
                    Object.keys(data).forEach(key => {
                        if (data[key] instanceof File) {
                            formData.append(key, data[key], data[key].name);
                        } else if (typeof data[key] === 'object' && data[key] !== null) {
                            formData.append(key, JSON.stringify(data[key]));
                        } else {
                            formData.append(key, data[key]);
                        }
                    });
                    contentType = 'multipart/form-data';
                } else {
                    formData = data;
                    contentType = 'application/json';
                }

                const responsePromise = client.post(`/${key}`, formData || data, {
                    headers: { 'Content-Type': contentType }
                });

                const response = await responsePromise;
                return response;
            } catch (error) {
                onError(`Error calling integration ${key}:  ${error.response?.data?.detail || error.message}.\ninput parameters:\n---${JSON.stringify(data)}\n---`);
                throw error;
            }
        }
    });
    return integrationsClient;
}
