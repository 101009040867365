import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../../../context/AppContext';
import Markdown from '../../Markdown';

export default function AppPage({ page, onCodePage }) {
    const {app} = useApp();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleCodePage = async () => {
        setLoading(true);
        await onCodePage(page.id);
        setLoading(false);
    };

    const handleShowPage = () => {
        navigate(`/apps/${app.id}/show/${page.name}`);
    };

    return (
        <div key={page.id} className="mb-4">
            <h2 className="text-2xl font-bold mb-2">{page.title}</h2>
            {
                page.url_parameters?.length > 0 && (
                    <div>
                        <p className="mb-2">URL Parameters: {JSON.stringify(page.url_parameters)}</p>
                    </div>
                )
            }
            <div className='mb-4'>
                <Markdown>{page.description}</Markdown>
            </div>
            {page.code && (
                <>
                    <button 
                        className="btn btn-secondary me-2"
                        // disabled={app.status?.state !== "ready"}

                        onClick={handleShowPage}
                    >
                        Show Page
                    </button>
                    <button 
                        className={`btn ${loading ? 'loading' : ''}`}
                        onClick={handleCodePage}
                        // disabled={app.status?.state !== "ready" || loading}
                    >
                        Re-implement Page
                    </button>
                </>
            )}
            <div className="divider"></div>
        </div>
    );
}