import React, { useState } from 'react';
import Loader from '../../Loader';
import ComponentPreview from '../../preview/ComponentPreview';
import { useApp } from '../../../context/AppContext';
import AppDemoHeader from './AppDemoHeader';
import {AlertCircle} from 'lucide-react'

const AppErrors = ({ appErrors, tryFixError, isFixing }) => {
    return <div role="alert" className="alert shadow-lg">  
                <AlertCircle className="text-red-500" />
                <div>
                    <h3>Error occurred: </h3>
                    {appErrors.map((err, idx) => <div key={idx} className="text-gray-500">{err}</div>)}
                </div>
                <button 
                    className="btn btn-sm btn-primary" 
                    onClick={tryFixError}
                    disabled={isFixing}
                >
                    {isFixing ? (
                        <>
                            <Loader className="mr-2 h-4 w-4 animate-spin" />
                            Fixing...
                        </>
                    ) : (
                        'Ask AI to fix it'
                    )}
                </button>               
            </div>
}


export default function AppDemo() {
    const { app, entitiesSDK, userInfo, updateUserInfo, integrationsSDK, currentPage, makePageChanges, onAppError, appErrors } = useApp();
    const [isFixing, setIsFixing] = useState(false);

    const tryFixError = async () => {
        setIsFixing(true);
        const errorAsString = appErrors.length === 1
            ? `the following error happened in the app: ${appErrors[0]}.\n---\nplease help me fix it`
            : `the following errors happened in the app:\n${appErrors.map((error, index) => 
                `Error ${index + 1}: ${error}\n`
              ).join('\n\n')}\n---\nplease help me fix these errors`;
        await makePageChanges(currentPage.id, errorAsString);
        setIsFixing(false);
    }

    const parseUrlParameters = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {};
        urlParams.forEach((value, key) => {
            params[key] = value;
        });
        return params;
    }

    const urlParams = parseUrlParameters();
    const navigateToPage = (page, urlParams) => {
        // check if page is not in app throw error:
        if (!app.pages.some(p => p.id === page || p.name === page)) {
            onAppError(`trying to navigate to a page that does not exist: ${page}`);
            return;
        }
        // using window because we need to reload the page, because of the same component render bug in ComponentPreview
        if (urlParams) {
            window.location.href = `/apps/${app.id}/show/${page}?${new URLSearchParams(urlParams).toString()}`;
        } else {
            window.location.href = `/apps/${app.id}/show/${page}`;
        }
    }

    if (!currentPage) {
        return <div className="alert alert-error">Page not found</div>;
    }

    if (app.status?.state === "processing") {
        return <div className="fixed inset-0 flex justify-center items-center w-screen h-screen bg-base-300">
            <Loader />
        </div>
    }

    if (app.status?.state === "error") {
        return <div className="alert alert-error">Error: {app.status?.details}</div>;
    }

    if (appErrors.length > 0) {
        return <div className="min-h-screen flex flex-col">
            <AppErrors appErrors={appErrors} tryFixError={tryFixError} isFixing={isFixing} />
        </div>
    }

    return (
        <div className="min-h-screen flex flex-col" id="app-demo" dir={app.language_direction}>            
            <AppDemoHeader page={currentPage} />
            <div className="flex-grow flex overflow-auto">
                <main className="flex-grow" >
                    <div id="component-preview-container">
                        <ComponentPreview 
                            componentCode={currentPage.code}
                            componentName={currentPage.name}
                            componentProps={{ 
                                entities: entitiesSDK, 
                                navigateToPage: navigateToPage, 
                                user: userInfo,
                                updateUserData: updateUserInfo,
                                integrations: integrationsSDK,
                                onError:onAppError,
                                urlParams: urlParams
                            }}
                            onError={onAppError}
                        />
                    </div>
                </main>
            </div>
        </div>
    );
}