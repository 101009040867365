import Markdown from '../../components/Markdown';

const TermsOfServiceText = `
# Terms of Service for base44

*Effective Date: 2024-07-20*

Welcome to base44! By accessing or using our productivity application, you agree to be bound by these Terms of Service ("Terms"). Please read them carefully.

## 1. Acceptance of Terms

By creating an account or using base44, you acknowledge that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you do not agree with any part of these Terms, please do not use our services.

## 2. Description of Service

base44 provides a productivity platform that allows users to automate tasks and receive notifications about their daily activities, including calendar events.

## 3. User Accounts

- **Registration**: You must provide accurate and complete information when creating an account.
- **Account Security**: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
- **Eligibility**: You must be at least [Insert Minimum Age] years old to use base44.

## 4. Use of Service

- **Compliance**: You agree to use base44 in compliance with all applicable laws and regulations.
- **Prohibited Activities**: You shall not misuse our services, interfere with their operation, or access them using unauthorized methods.

## 5. Calendar Integration

If you choose to integrate your calendar:

- **Permissions**: You grant base44 permission to access your calendar data solely for the purpose of providing notifications and enhancing your productivity.
- **Data Use**: We will not use your calendar information for any other purpose without your explicit consent.
- **Revocation**: You can revoke calendar access at any time through your account settings.

## 6. Intellectual Property

- **Ownership**: All content, features, and functionality of base44 are the exclusive property of base44 and are protected by intellectual property laws.
- **License**: We grant you a limited, non-exclusive, non-transferable license to use base44 for personal or internal business purposes.

## 7. Termination

- **By User**: You may terminate your account at any time.
- **By base44**: We reserve the right to suspend or terminate your access to base44 at our discretion, without prior notice, if you violate these Terms or for any other reason.

## 8. Disclaimer of Warranties

base44 is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the service's performance or availability.

## 9. Limitation of Liability

To the maximum extent permitted by law, base44 shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the service.

## 10. Indemnification

You agree to indemnify and hold harmless base44 and its affiliates from any claims, damages, or expenses arising out of your use of the service or violation of these Terms.

## 11. Changes to Terms

We may modify these Terms at any time. We will notify you of any significant changes by posting the new Terms on this page. Your continued use of base44 after changes have been made constitutes your acceptance of the new Terms.

## 12. Governing Law

These Terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction], without regard to its conflict of law provisions.

## 13. Contact Us

If you have any questions about these Terms, please contact us at info@base44.com.

---

By using base44, you agree to these Terms of Service. Thank you for choosing base44 to enhance your productivity!
`;


export default function TermsOfService() {
    return <div className="p-9"><Markdown>{TermsOfServiceText}</Markdown></div>;
}