import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppWindow, MoreHorizontal } from 'lucide-react';
import UserAppAPI from '../api/UserAppAPI';
import moment from 'moment';

function UserApps() {
    const [userApps, setUserApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserApps();
    }, []);

    const fetchUserApps = async () => {
        try {
            const apps = await UserAppAPI.list();
            setUserApps(apps);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch user apps:', error);
            setLoading(false);
        }
    };

    const handleAppClick = (appId) => {
        navigate(`/apps/${appId}/dashboard`);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
        <div className="p-4 bg-base-200 min-h-screen">
            <h2 className="text-2xl font-bold mb-6">Your Apps</h2>
            <div className="grid gap-4">
                {userApps.map((app) => (
                    <div key={app.id} className="card bg-base-100 shadow-xl hover:shadow-2xl transition-shadow duration-300 cursor-pointer" onClick={() => handleAppClick(app.id)}>
                        <div className="card-body">
                            <div className="flex items-center mb-2">
                                {app.logo_url ? (
                                    <div className="avatar mr-4">
                                        <div className="w-12 h-12 rounded-full">
                                            <img src={app.logo_url} alt={`${app.name} logo`} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="avatar placeholder mr-4">
                                        <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                                            <AppWindow size={24} />
                                        </div>
                                    </div>
                                )}
                                <h3 className="card-title">{app.name}</h3>
                            </div>
                            <p className="text-base-content/70 mb-2 line-clamp-2">
                                {app.user_description || 'No description'}
                                {app.user_description && app.user_description.length > 100 && (
                                    <MoreHorizontal className="inline ml-1" size={16} />
                                )}
                            </p>
                            <div className="text-xs text-base-content/50">
                                Created by: {app.created_by} | {moment(app.created_date).fromNow()}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UserApps;